.preview-area__basic-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.header {
  font-size: 24px;
}

.professional__title {
  font-size: 18px;
  font-weight: 400;
}

.self-summary {
  font-size: 12px;
}
