.header {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header-title {
  font-size: 42px;
  font-weight: 700;
  color: #a855f7;
}

.header-description {
  font-size: 18px;
  font-weight: 400;
}

.header-github {
  font-size: 16px;
  border-bottom: 1px solid #000;
  width: fit-content;
}

.header-github:active {
  color: #a855f7;
}
