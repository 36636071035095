html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "Daydream", sans-serif;
}

.form__basic-info {
  display: flex;
  flex-direction: column;

  outline: none;
  border: none;
  margin-top: 30px;
  margin-bottom: 40px;
}

.form__title {
  font-size: 26px;
  border-bottom: 2px solid #94a3b8;
  padding-bottom: 6px;
  margin-bottom: 20px;
  font-weight: 500;
}

.form__fieldset {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  outline: none;
  border: none;
  margin-bottom: 20px;
  padding: 0px;
}

.label__btn {
  background-color: #fff;
  font-size: 16px;
  outline: none;
  border: 2px solid #94a3b8;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.label__btn:hover {
  border: 2px solid #a855f7;
}

.label__btn:active {
  background-color: #e0e7ff;
  border: 2px solid #a855f7;
}
