.form-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #94a3b8;
  border-radius: 5px;
  padding-bottom: 10px;
}

.form-input {
  position: relative;
  padding: 5px;
  padding-left: 10px;
  font-size: 16px;
}

.form-label__title {
  margin-left: 6px;
  transform: translateY(-50%);

  z-index: 10;
  background-color: #fff;
  width: fit-content;
  padding: 0px 5px 0px 5px;
}
