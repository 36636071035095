input,
textarea {
  outline: none;
  border: none;
}

input:focus,
textarea:focus {
  outline: none;
  border: none;
}

.form-label {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-input {
  max-height: 150px;
  min-height: 50px;
  resize: none;
  border: 2px solid #94a3b8;
  border-radius: 5px;
  padding: 5px 5px 0px 10px;
  font-size: 16px;
  padding-top: 20px;
}

.form-input:focus {
  border: 2px solid #a855f7;
}

.form-label__title {
  margin-left: 6px;
  transform: translateY(50%);

  z-index: 10;
  background-color: #fff;
  width: fit-content;
  padding: 0px 5px 0px 5px;
}
